import { React, useMemo, useState, useEffect } from "react";
import { Dropdown, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Form, Alert, Modal, Button } from "react-bootstrap";
import { Row, Col, Card, Table, ProgressBar } from "react-bootstrap";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import DoctorsReview from "../Dashboard/Doctors-Review";


const Modules = () => {
  // const apiUrl = "http://localhost:3000/api/modules";
  // const apiUrl2 = "http://localhost:3000";

  const apiUrl = "https://quiz-ws.azurewebsites.net/api/modules";
  const apiUrl2 = "https://quiz-ws.azurewebsites.net";
  const [showModal, setShowModal] = useState(false);

  const [counts, setCounts] = useState({
    totalModules: 0,
    activeModules: 0,
    upcomingModules: 0,
    completedModules: 0,
  });

  const [modulesData, setModulesData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [editStatus, setEditStatus] = useState("");
  const [openModal, setOpenModal] = useState();

  const getCloseAlert = () => {
    setShowModal(false);
  };

  // Function to fetch data from the API
  const fetchModulesData = async () => {
    const jwtToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwtToken="))
      ?.split("=")[1];

    if (!jwtToken) {
      console.error("Authorization token not found in cookies.");
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      setModulesData(data); // Store the response in the state
    } catch (error) {
      console.error("Error fetching module data:", error);
    }
  };
  const fetchCounts = async () => {
    try {
      const jwtToken = Cookies.get("jwtToken");
      if (!jwtToken) {
        throw new Error("JWT token not found");
      }

      const response = await fetch(
        `${apiUrl2}/api/admin/analytics/user-counts`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      );

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);

      const data = await response.json();
      setCounts({
        totalModules: data.moduleCount,
        activeModules: data.moduleActiveCount,
        upcomingModules: data.moduleHoldCount,
        completedModules: data.moduleClosedCount,
      });
    } catch (error) {
      console.error(error);
      toast.error("Error fetching counts data");
    } finally {
      setLoading(false);
    }
  };
  
  const handleDelete = async (moduleId) => {
    console.log("Delete button is clicked");
    const jwtToken = document.cookie
      .split("; ")
      .find((row) => row.startsWith("jwtToken="))
      ?.split("=")[1];

    if (!jwtToken) {
      console.error("Authorization token not found in cookies.");
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/${moduleId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
        console.log(response.status);
      } else {
        console.log(response);
      }

      // Remove the deleted module from the state
      setModulesData(
        modulesData.filter((module) => module.moduleId !== moduleId)
      );
      setShowModal(true);
    } catch (error) {
      console.error("Error deleting module:", error);
      alert("Failed to delete module. Please try again.");
    }
  };

  const handleEdit = (module) => {
    setCurrentModule(module);
    setEditStatus(module.status);
    setShowEditModal(true);
  };

  const handleSave = async () => {
    const jwtToken = Cookies.get("jwtToken");
    if (!jwtToken) {
      console.error("JWT token not found");
      return;
    }

    try {
      const response = await fetch(
        `${apiUrl2}/api/modules/${currentModule.moduleId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ status: editStatus }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Update the module in the frontend immediately after successful update
      const updatedModuleData = {
        ...currentModule,
        status: editStatus
      };
      
      setModulesData(
        modulesData.map((module) =>
          module.moduleId === currentModule.moduleId ? updatedModuleData : module
        )
      );
      setShowEditModal(false);
      toast.success("Module updated successfully");
    } catch (error) {
      console.error("Error updating module:", error);
      toast.error("Error updating module");
    }
  };
  const [moduleDetails, setModuleDetails] = useState(null);
  const [showModuleDetails, setShowModuleDetails] = useState(false);
  const [error, setError] = useState(null);

  const showModuledetails = async (moduleId) => {
    try {
      const jwtToken = Cookies.get("jwtToken");
      if (!jwtToken) {
        console.error("JWT token not found");
        return;
      }
      setLoading(true);
      const response = await fetch(`${apiUrl2}/api/modules/${moduleId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${jwtToken}`,
        },
      });
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      setModuleDetails(data);
      setShowModuleDetails(true);
      console.log(data);
    } catch (error) {
      console.error("Error fetching module details:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setShowModuleDetails(false);
  };

  // Fetch data when the component mounts
  useEffect(() => {
    fetchModulesData();
    fetchCounts();
  }, []);

  return (
    <div className="container" Style={{ paddingTop: "0px" }}>
      <Modal show={showModuleDetails} onHide={handleClose} size="lg" style={{ maxWidth: '120%', width: '90%' }}>
        <Modal.Header closeButton >
          <Modal.Title>Module Details</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {loading && <Spinner animation="border" />}
          {error && <div>Error: {error}</div>}
          {moduleDetails && (
            <Card >
              <Card.Header>{moduleDetails.title}</Card.Header>
              <Card.Body>
                <Card.Title>{moduleDetails.description}</Card.Title>
                <Card.Text>
                  <strong>Status:</strong> {moduleDetails.status}
                </Card.Text>
                <Card.Text>
                  <strong>Content:</strong>
                  <div>
                    <p><strong>Lesson Name:</strong> {moduleDetails.content.lessonname}</p>
                    <p><strong>Color:</strong> {moduleDetails.content.color}</p>
                    <h4>Slides:</h4>
                    {moduleDetails.content.slides.map((slide, index) => (
                      <div key={index}>
                        <h5>Slide {slide.slideno}</h5>
                        <p>{slide.textbox}</p>
                        {slide.quiz && (
                          <div>
                            <h6>Quiz Question {slide.quiz.questionNo}</h6>
                            <p>{slide.quiz.questionText}</p>
                            <ul>
                              {slide.quiz.options.map((option, optIndex) => (
                                <li key={optIndex}>
                                  {option.label}: {option.optionText}
                                </li>
                              ))}
                            </ul>
                            <p><strong>Correct Answer:</strong> {slide.quiz.correctAnswer}</p>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </Card.Text>
                <Card.Text>
                  <strong>Module ID:</strong> {moduleDetails.moduleId}
                </Card.Text>
                <Card.Text>
                  <strong>Linked Quizzes:</strong>{" "}
                  {moduleDetails.linkedQuizzes.join(", ") || "None"}
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <small className="text-muted">
                  Created at:{" "}
                  {new Date(moduleDetails.createdAt).toLocaleString()}
                </small>
                <br />
                <small className="text-muted">
                  Updated at:{" "}
                  {new Date(moduleDetails.updatedAt).toLocaleString()}
                </small>
              </Card.Footer>
            </Card>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Module has been Delted successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={getCloseAlert}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* <div className="form-head d-flex mb-3 mb-lg-5 align-items-start flex-wrap"></div> */}

      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="row" style={{ marginLeft: "1px", maxWidth: "540px" }}>
              <Link
                to={"/create-module"}
                className="btn btn-success ms-auto px-5 mb-4"
                onClick={() => setOpenModal(true)}
                style={{fontWeight:'bold', fontSize:'20px'}}
              >
               + Add Learning Module
              </Link>
            </div>
            <div className="row">
              {/* Card 1 */}
              <div className="col-xl-6 col-lg-6 col-sm-6 mb-4">
                <div className="widget-stat card">
                  <div className="card-body p-4">
                    <div className="media ai-icon">
                      <span className="me-3 bgl-primary text-primary">
                        <svg
                          id="icon-customers"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                      </span>
                      <div className="media-body">
                        <p className="mb-1">TOTAL MODULES</p>
                        {loading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          counts.totalModules
                        )}
                        {/* <span className="badge badge-primary">+3.5%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Card 4 */}
              <div className="col-xl-6 col-lg-6 col-sm-6 mb-4">
                <div className="widget-stat card">
                  <div className="card-body p-4">
                    <div className="media ai-icon">
                      <span className="me-3 bgl-success text-success">
                        <svg
                          id="icon-database-widget"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-database"
                        >
                          <ellipse cx="12" cy="5" rx="9" ry="3"></ellipse>
                          <path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path>
                          <path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path>
                        </svg>
                      </span>
                      <div className="media-body">
                        <p className="mb-1">ACTIVE MODULES</p>
                        {loading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          counts.activeModules
                        )}{" "}
                        {/* <span className="badge badge-success">-3.5%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 2 */}
              <div className="col-xl-6 col-lg-6 col-sm-6 mb-4">
                <div className="widget-stat card">
                  <div className="card-body p-4">
                    <div className="media ai-icon">
                      <span className="me-3 bgl-warning text-warning">
                        <svg
                          id="icon-orders"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-file-text"
                        >
                          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                          <polyline points="14 2 14 8 20 8"></polyline>
                          <line x1="16" y1="13" x2="8" y2="13"></line>
                          <line x1="16" y1="17" x2="8" y2="17"></line>
                          <polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                      </span>
                      <div className="media-body">
                        <p className="mb-1">UPCOMING MODULES</p>
                        {loading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          counts.upcomingModules
                        )}{" "}
                        {/* <span className="badge badge-warning">+3.5%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Card 3 */}
              <div className="col-xl-6 col-lg-6 col-sm-6 mb-4">
                <div className="widget-stat card">
                  <div className="card-body p-4">
                    <div className="media ai-icon">
                      <span className="me-3 bgl-danger text-danger">
                        {/* <svg
                          id="icon-revenue"
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-dollar-sign"
                        >

                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg> */}
                  <i className=" flaticon-381-blueprint"></i>
                      </span>
                      <div className="media-body">
                        <p className="mb-1">COMPLETED MODULES</p>
                        {loading ? (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        ) : (
                          counts.completedModules
                        )}{" "}
                        {/* <span className="badge badge-danger">-3.5%</span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Right side - Todo list */}
        <div className="col-xl-6 col-xxl-6 col-lg-6">
          <div className="card border-0 pb-0">
            <div className="card-header border-0 pb-0">
              <h4 className="card-title">To Do List</h4>
            </div>
            <div className="card-body p-0">
              <div
                id="DZ_W_Todo4"
                className="widget-media dz-scroll height350 my-6 px-4 "
              >
                <ul className="timeline">
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox1"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox1"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Schedule Progress Check-ins

                        </h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox2"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox2"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Define Clear Module Objectives</h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-primary check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox3"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox3"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Review and Approve Module Outline
                        </h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-info check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox4"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox4"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Provide Constructive Feedback on Drafts
                        </h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox5"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox5"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Approve Final Module and Launch Timeline
                        </h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                  <li>
                    <div className="timeline-panel">
                      <div className="form-check custom-checkbox checkbox-warning check-lg me-3">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheckBox6"
                          required=""
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customCheckBox6"
                        ></label>
                      </div>
                      <div className="media-body">
                        <h5 className="mb-0">Establish Feedback Mechanisms
                        </h5>
                        <small className="text-muted">
                          29 July 2023 - 02:26 PM
                        </small>
                      </div>
                      <Dropdown className="dropdown">
                        <Dropdown.Toggle
                          variant="primary light"
                          className=" i-false p-0 sharp"
                        >
                          <svg
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x="0" y="0" width="24" height="24" />
                              <circle fill="#000000" cx="5" cy="12" r="2" />
                              <circle fill="#000000" cx="12" cy="12" r="2" />
                              <circle fill="#000000" cx="19" cy="12" r="2" />
                            </g>
                          </svg>
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu">
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown-item"
                            to="/widget-basic"
                          >
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
        <Card>
          <Card.Header>
            <Card.Title>Recent Modules</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th className="width50">
                    <strong></strong>
                  </th>
                  <th>
                    <strong>Moudule Id</strong>
                  </th>
                  <th>
                    <strong> Name</strong>
                  </th>
                  <th>
                    <strong> Date</strong>
                  </th>
                  <th>
                    <strong> Status</strong>
                  </th>
                  <th>
                    <strong> Action</strong>
                  </th>
                </tr>
              </thead>

              <tbody>
                {modulesData.length > 0 ? (
                  modulesData.map((module, index) => {
                    let formattedDate = "";
                    if (module.createdAt) {
                      const createdAt = new Date(module.createdAt);
                      formattedDate = `${String(createdAt.getDate()).padStart(
                        2,
                        "0"
                      )}-${String(createdAt.getMonth() + 1).padStart(
                        2,
                        "0"
                      )}-${createdAt.getFullYear()}`;
                    } else {
                      formattedDate = "None";
                    }
                    return (
                      <tr key={index}>
                        <td>
                          <strong>{index + 1}</strong> {/* S.No Column */}
                        </td>
                        <td onClick={() => showModuledetails(module.moduleId)}>
                          {module.moduleId}
                        </td>
                        <td>{module.title}</td>
                        <td>{formattedDate}</td> {/* Display formatted date */}
                        <td>
                          <span
                            className={`badge light badge-${
                              module.status === "active" ? "success" : "warning"
                            }`}
                          >
                            {module.status}
                          </span>
                        </td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm me-2"
                            onClick={() => showModuledetails(module.moduleId)}
                          >
                            <i className="fa fa-eye"></i>
                          </button>
                          <Dropdown className="d-inline-block">
                            <Dropdown.Toggle
                              variant="success"
                              className="light sharp i-false"
                            >
                              ▼
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleEdit(module)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(module.moduleId)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  // <tr>
                  //     <td colSpan="6">No modules available</td>

                  // </tr>

                  <tr>
                    <td colSpan="6" className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        {/* Edit Modal */}
        <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Module</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={editStatus}
                  onChange={(e) => setEditStatus(e.target.value)}
                >
                  <option value="active">Active</option>
                  <option value="hold">Hold</option>
                  <option value="closed">Closed</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <ToastContainer />

      <DoctorsReview/>

    </div>
  );
};

export default Modules;
