import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          © Designed &amp; Developed by{" "}
          <a href="http://aquarlabs.com/" target="_blank">
            AquarLabs
          </a>{" "}
          2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
