import React, { Fragment, useState, useEffect } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import MsgBox from "../chatBox/MsgBox";
import avatar1 from "../../../images/avatar/1.jpg";
import Select from "react-select";

// const apiUrl = `http://localhost:3000/api`;
const apiUrl = `https://quiz-ws.azurewebsites.net/api`;

const CreateQuiz = () => {
  const [title, setTitle] = useState(""); // This will be the quiz title input
  const [description, setDescription] = useState(""); // Module description (optional)
  const [lessons, setLessons] = useState({});
  const [lessonCount, setLessonCount] = useState(1);
  const [openMsg, setOpenMsg] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const navigate = useNavigate();

  // Fetch modules for the dropdown
  useEffect(() => {
    const fetchModules = async () => {
      const token = Cookies.get("jwtToken");
      try {
        const response = await fetch(`${apiUrl}/modules`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const options = data.map((module) => ({
          value: module.moduleId,
          label: module.title,
        }));
        setModuleOptions(options);
      } catch (error) {
        console.error("Error fetching modules:", error);
      }
    };
    fetchModules();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = Cookies.get("jwtToken");
    if (!token) {
      console.error("JWT token is missing");
      return;
    }

    // Construct the questions array based on the lessons object
    const questions = Object.keys(lessons).map((lessonId, index) => ({
      questionNo: index,
      questionText: lessons[lessonId]?.questionName || "No question text",
      options: Object.keys(lessons[lessonId]?.options || {}).map((key) => ({
        label: key,
        optionText: lessons[lessonId].options[key] || "No option text",
        optionMediaUrl: lessons[lessonId].optionMediaUrl || "", // Assuming optionMediaUrl is provided in the lesson data
      })),
      correctAnswer: lessons[lessonId]?.correctAnswer || "",
    }));

    // Prepare the request body
    console.log(selectedModule.id);
    const requestData = {
      linkedModuleId: selectedModule || "string", // Fallback to "string" if selectedModule is not defined
      title: title,
      questions: questions,
      timeLimit: 0, // Default time limit as in the curl request
    };

    try {
      const response = await fetch(`${apiUrl}/quizzes`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      // Handle response
      if (!response.ok) {
        const errorData = await response.json();
        console.error("Error response status:", response.status);
        console.error("Error response data:", errorData);
        throw new Error(
          `Failed to create quiz: ${errorData.message || response.statusText}`
        );
      }

      // Success
      const data = await response.json();
      console.log("Quiz created successfully:", data);
      setShowModal(true);
    } catch (error) {
      console.error("Error creating quiz:", error);
    }
  };

  // Handle adding a new lesson (question)
  const handleAddLesson = () => {
    const newLessonId = `lesson-${lessonCount}`;
    setLessonCount(lessonCount + 1);
    setLessons({
      ...lessons,
      [newLessonId]: {
        questionName: "",
        options: { A: "", B: "", C: "", D: "" },
        correctAnswer: "",
      },
    });
  };

  // Handle changes in question fields (name, options, correct answer)
  const handleLessonChange = (e, lessonId, field) => {
    setLessons({
      ...lessons,
      [lessonId]: {
        ...lessons[lessonId],
        [field]: e.target.value,
      },
    });
  };

  // Handle changes to options (A, B, C, D)
  const handleOptionChange = (e, lessonId, option) => {
    setLessons({
      ...lessons,
      [lessonId]: {
        ...lessons[lessonId],
        options: {
          ...lessons[lessonId].options,
          [option]: e.target.value,
        },
      },
    });
  };

  // Handle deleting a lesson (question)
  const handleDeleteLesson = (lessonId) => {
    const newLessons = { ...lessons };
    delete newLessons[lessonId];
    setLessons(newLessons);
  };

  // Close success modal and navigate to home
  const getCloseAlert = () => {
    setShowModal(false);
    navigate("/quizes");
  };

  return (
    <Fragment>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Quiz has been created successfully.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={getCloseAlert}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="row">
        <div
          className="col-xl-12 d-flex flex-row justify-content-start"
          style={{ height: "650px" }}
        >
          <div className="card col-xl-8">
            <div
              className="card-body"
              style={{ height: "100px", paddingBottom: "10px" }}
            >
              <div className="row">
                <div
                  className="col-md-12 order-md-1 overflow-scroll"
                  style={{
                    height: "75vh",
                    overflowY: "auto",
                    paddingBottom: "10px",
                  }}
                >
                  <h4 className="mb-3">Create Quiz</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label className="form-label">Module</label>
                      <Select
                        options={moduleOptions}
                        className="custom-react-select"
                        onChange={(option) => setSelectedModule(option.value)}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Quiz Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Quiz Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Questions</label>
                      {Object.keys(lessons).map((lessonKey) => (
                        <div key={lessonKey} className="mb-3">
                          <div>
                            <div>
                              <label className="form-label">
                                Question Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={lessons[lessonKey].questionName}
                                onChange={(e) =>
                                  handleLessonChange(
                                    e,
                                    lessonKey,
                                    "questionName"
                                  )
                                }
                                placeholder="Enter question"
                              />

                              {["A", "B", "C", "D"].map((option) => (
                                <div key={option} className="mb-2">
                                  <label className="form-label">
                                    Option {option}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={lessons[lessonKey].options[option]}
                                    onChange={(e) =>
                                      handleOptionChange(e, lessonKey, option)
                                    }
                                    placeholder={`Option ${option}`}
                                  />
                                </div>
                              ))}

                              <label className="form-label">
                                Correct Answer
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                value={lessons[lessonKey].correctAnswer}
                                onChange={(e) =>
                                  handleLessonChange(
                                    e,
                                    lessonKey,
                                    "correctAnswer"
                                  )
                                }
                                placeholder="Correct answer (A, B, C, D)"
                              />

                              <button
                                type="button"
                                className="btn btn-danger btn-sm mt-2"
                                onClick={() => handleDeleteLesson(lessonKey)}
                              >
                                Delete Question
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <button
                      type="button"
                      className="btn btn-secondary mb-3"
                      onClick={handleAddLesson}
                    >
                      Add New Question
                    </button>

                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                    >
                      Create
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="m-2">
            <MsgBox
              avatar1={avatar1}
              openMsg={openMsg}
              offMsg={() => setOpenMsg(false)}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateQuiz;
