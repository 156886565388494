import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import axios from "axios";
import { Card, Button, Dropdown, Form, InputGroup, Image } from "react-bootstrap";
import avatar from "../../../images/avatar/mediai.jpg";

const MsgBox = ({ avatar1, avatar2, openMsg, offMsg }) => {
  const [toggle, setToggle] = useState(false);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hi, how can I Help you?",
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    },
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const token = Cookies.get("jwtToken");
  const apiUrl = `https://quiz-ws.azurewebsites.net/api`;

  const handleSendMessage = async () => {
    if (inputMessage.trim() === "") return;

    const newMessage = {
      sender: "user",
      text: inputMessage,
      time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
    };

    setMessages([...messages, newMessage]);
    setInputMessage("");
    setIsTyping(true);

    try {
      const response = await axios.post(
        `${apiUrl}/modules/learner`,
        { message: inputMessage },
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const botMessage = {
        sender: "bot",
        text: response.data.response,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error sending message:", error);
    } finally {
      setIsTyping(false);
    }
  };

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
  };

  return (
    <Card className={''} style={{width: "400px", maxWidth: "400px"}}>
      <style>
        {`
          .typing {
            display: flex;
            align-items: center;
          }

          .typing span {
            display: inline-block;
            width: 8px;
            height: 8px;
            margin: 0 2px;
            background: #ccc;
            border-radius: 50%;
            animation: typing 1s infinite;
          }

          .typing span:nth-child(2) {
            animation-delay: 0.2s;
          }

          .typing span:nth-child(3) {
            animation-delay: 0.4s;
          }

          @keyframes typing {
            0%, 60% {
              transform: translateY(0);
            }
            30% {
              transform: translateY(-8px);
            }
          }
        `}
      </style>
      <Card.Header className="d-flex justify-content-between align-items-center" >
        {/* <Button variant="link" onClick={() => offMsg()}>
          <i className="fa fa-arrow-left"></i>
        </Button> */}
        <div>
          <h5 className="mb-0">Medi Mentor</h5>
          <p className=" text-success" style={{marginBottom:'-15px'}}>Online</p>
        </div>
                <Image src={avatar} roundedCircle className="user_img_msg" style={{width: "40px", height: "40px"}} />

      </Card.Header>
      <Card.Body className="msg_card_body dz-scroll">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`d-flex ${msg.sender === "user" ? "justify-content-end" : "justify-content-start"} mb-4`}
          >
            {msg.sender === "bot" && (
              <div className="img_cont_msg">
                {/* <Image src={avatar1} roundedCircle className="user_img_msg" /> */}
              </div>
            )}
            <div className={`msg_cotainer${msg.sender === "user" ? "_send" : ""}`} style={{
              backgroundColor: msg.sender === "user" ? "#ECE6DA" : "#C9DEDC",
              borderRadius: "15px",
              padding: "10px 15px",
              maxWidth: "75%",
              position: "relative",
              marginBottom: "5px",
              boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
              color: msg.sender === "user" ? "#313131" : "#293D3B",
              fontSize: "14px",
              display: msg.sender === "user" ? "flex" : "",
              alignContent : msg.sender === "user" ? "center" : "",
              alignItems: msg.sender === "user" ? "center" : "",
            }}>
              {msg.text.split('\n').map((line, i) => (
                <span key={i}>
                  {line.split('**').map((part, j) => (
                    j % 2 === 1 ? <strong key={j}>{part}</strong> : 
                    part.startsWith('# ') && !part.startsWith('## ') ? 
                      <h1 key={j} style={{fontSize: '2em', fontWeight: 'bold', margin: '15px 0'}}>
                        {part.replace('# ', '')}
                      </h1> :
                    part.startsWith('## ') && !part.startsWith('### ') ? 
                      <h2 key={j} style={{fontSize: '1.75em', fontWeight: 'bold', margin: '12px 0'}}>
                        {part.replace('## ', '')}
                      </h2> :
                    part.startsWith('### ') && !part.startsWith('#### ') ?
                      <h3 key={j} style={{fontSize: '1.5em', fontWeight: 'bold', margin: '10px 0'}}>
                        {part.replace('### ', '')}
                      </h3> :
                    part.startsWith('#### ') ?
                      <h4 key={j} style={{fontSize: '1.25em', fontWeight: 'bold', margin: '8px 0'}}>
                        {part.replace('#### ', '')}
                      </h4> :
                      <span key={j}>{part}</span>
                  ))}
                  <br/>
                </span>
              ))}
              <br/>
              <span className={`msg_time${msg.sender === "user" ? "_send" : ""}`} style={{
                fontSize: "10px",
                color: "#999",
                position: "absolute",
                bottom: "-20px",
                [msg.sender === "user" ? "right" : "left"]: "0"
              }}> {msg.time}</span>
            </div>
            {msg.sender === "user" && (
              <div className="img_cont_msg">
                {/* <Image src={avatar2} roundedCircle className="user_img_msg" /> */}
              </div>
            )}
          </div>
        ))}
        {isTyping && (
          <div className="d-flex justify-content-start mb-4">
            <div className="img_cont_msg">
              {/* <Image src={avatar1} roundedCircle className="user_img_msg" /> */}
            </div>
            <div className="msg_cotainer" style={{
              backgroundColor: "#ffffff",
              borderRadius: "15px",
              padding: "10px 15px",
              maxWidth: "75%",
              position: "relative",
              marginBottom: "5px",
              boxShadow: "0 1px 1px rgba(0,0,0,0.1)"
            }}>
              <div className="typing">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )}
      </Card.Body>
      <Card.Footer className="type_msg">
        <InputGroup>
          <Form.Control
            as="textarea"
            placeholder="Type your message..."
            value={inputMessage}
            onChange={handleInputChange}
          />
          <Button variant="primary" onClick={handleSendMessage}>
            <i className="fa fa-location-arrow"></i>
          </Button>
        </InputGroup>
      </Card.Footer>
    </Card>
  );
};

export default MsgBox;
