import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Carousel,
  Badge,
  Modal,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Draggable from "react-draggable";
import { Resizable } from "re-resizable";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const MobileSlider = () => {
  const [slides, setSlides] = useState([
    { text: "", image: "", quiz: null, imageLink: "" },
  ]);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [bgColor, setBgColor] = useState("#211C1C");
  const [title, setTitle] = useState("");
  const [showQuizModal, setShowQuizModal] = useState(false);
  const [quizQuestion, setQuizQuestion] = useState("");
  const [quizOptions, setQuizOptions] = useState(["", "", "", ""]);
  const [quizAnswer, setQuizAnswer] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [imageFile, setImageFile] = useState(null);
   const nav = useNavigate();

  const addSlide = () => {
    setSlides([...slides, { text: "", image: "", quiz: null, imageLink: "" }]);
    setCurrentSlideIndex(slides.length); // Set the current slide to the newly added slide
  };

  const handleTextChange = (text) => {
    const newSlides = [...slides];
    newSlides[currentSlideIndex].text = text;
    setSlides(newSlides);
  };

  const handleImageChange = (file) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const newSlides = [...slides];
      newSlides[currentSlideIndex].image = reader.result;
      setSlides(newSlides);
    };
    reader.readAsDataURL(file);
  };

  const handleImageLinkChange = () => {
    const newSlides = [...slides];
    newSlides[currentSlideIndex].imageLink = imageLink;
    setSlides(newSlides);
    setShowImageModal(false);
  };

  const handleBgColorChange = (e) => {
    setBgColor(e.target.value);
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleQuizChange = () => {
    const newSlides = [...slides];
    newSlides[currentSlideIndex].quiz = {
      question: quizQuestion,
      options: quizOptions,
      answer: quizAnswer,
    };
    setSlides(newSlides);
    setShowQuizModal(false);
  };

  const handleQuizOptionChange = (index, value) => {
    const newOptions = [...quizOptions];
    newOptions[index] = value;
    setQuizOptions(newOptions);
  };
const apiUrl = `http://localhost:3000`;

  const createModule = async () => {
  const jwtToken = Cookies.get("jwtToken");
    const moduleData = {
      title: title,
      description: " ",
      content: {
        lessonname: "Coding 101",
        color: bgColor,
        backgroundimg: slides[0].image || "", // Use the first slide's image as background if available
        slides: slides.map((slide, index) => ({
          slideno: index + 1,
          textbox: slide.text,
          quiz: slide.quiz
            ? {
                questionNo: index + 1,
                questionText: slide.quiz.question,
                options: slide.quiz.options.map((option, i) => ({
                  label: String.fromCharCode(65 + i),
                  optionText: option,
                  optionMediaUrl: "", // Add your media URL if available
                })),
                correctAnswer: slide.quiz.answer,
              }
            : null,
        })),
      },
    };

    try {
      const response = await fetch(`${apiUrl}/api/modules`, {
        method: "POST",
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${jwtToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(moduleData),
      });

      if (response.ok) {
        Swal.fire({
          title: "Success!",
          text: "Module created successfully!",
          icon: "success",
          confirmButtonText: "OK"
        }).then(() => {
          nav("/modules");
        });

      } else {
        alert("Failed to create module.");
      }
    } catch (error) {
      console.error("Error creating module:", error);
      alert("Error creating module.");
    }
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center vh-100"
      style={{ marginTop: "-100px" }}
    >
      <Row>
        <Col>
          <div className="d-flex flex-column align-items-center">
            <div
              style={{
                position: "relative",
                border: "1px solid #ccc",
                borderRadius: "15px",
                width: "355px",
                height: "650px",
                backgroundColor: bgColor,
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "10px",
                  color: "white",
                  zIndex: 10,
                  marginBottom: "10px",
                }}
              >
                <p style={{ fontSize: "20px", fontWeight: "bold" }}>{title}</p>
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    left: "17rem",
                    display: "flex",
                  }}
                >
                  <Badge bg="" className="badge-info light">
                    ({currentSlideIndex + 1} of {slides.length})
                  </Badge>
                </div>
              </div>
              <Carousel
                activeIndex={currentSlideIndex}
                onSelect={(selectedIndex) =>
                  setCurrentSlideIndex(selectedIndex)
                }
                interval={null}
                style={{ marginTop: "40px" }}
              >
                {slides.map((slide, index) => (
                  <Carousel.Item key={index}>
                    <div
                      style={{
                        padding: "20px",
                        textAlign: "center",
                        height: "600px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {slide.text && (
                        <Draggable bounds="parent">
                          <Resizable
                            defaultSize={{ width: 200, height: 100 }}
                            style={{
                              cursor: "move",
                              padding: "10px",
                              backgroundColor: "rgba(255, 255, 255, 0.8)",
                              borderRadius: "10px",
                              position: "absolute",
                            }}
                          >
                            {slide.text}
                          </Resizable>
                        </Draggable>
                      )}
                      {slide.image && (
                        <Draggable bounds="parent">
                          <a
                            href={slide.imageLink || "#"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={slide.image}
                              alt={`Slide ${index}`}
                              style={{
                                maxWidth: "100%",
                                cursor: "move",
                                position: "absolute",
                              }}
                            />
                          </a>
                        </Draggable>
                      )}
                      {slide.quiz && (
                        <div style={{ textAlign: "left", width: "100%" }}>
                          <h5 style={{ marginBottom: "20px", color: "white" }}>
                            Question {index + 1}: {slide.quiz.question}
                          </h5>
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {slide.quiz.options.map((option, idx) => (
                              <li key={idx} style={{ marginBottom: "10px" }}>
                                <Button
                                  variant="outline-light"
                                  style={{
                                    width: "100%",
                                    textAlign: "left",
                                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                                    borderColor: "transparent",
                                    color: "#fff9",
                                  }}
                                >
                                  <strong>
                                    {String.fromCharCode(65 + idx)}.
                                  </strong>{" "}
                                  {option}
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>
        </Col>
        <Col className="d-flex flex-column">
          <Button
            onClick={addSlide}
            className="me-2 mb-3 "
            variant="info btn-rounded"
            style={{ width: "10rem" }}
          >
              <i className="fa fa-plus color-info" />
              Add Slide
          </Button>
          <Form.Control
            type="color"
            value={bgColor}
            onChange={handleBgColorChange}
            title="Choose background color"
            className="mb-3 rounded-circle"
            style={{ width: "50px", height: "50px", padding: "10px" }}
          />
          <Button
            onClick={() => setShowQuizModal(true)}
            className="me-2 mb-3 .btn-xs"
          >
            Add Quiz
          </Button>
          <Form.Control
            type="text"
            placeholder="Lesson title"
            value={title}
            onChange={handleTitleChange}
            className="mb-3"
          />
          <Form.Control
            type="text"
            placeholder="Add text"
            className="mb-3"
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleTextChange(e.target.value);
                e.target.value = "";
              }
            }}
          />
          <Button
            onClick={() => setShowImageModal(true)}
            className="me-2 mb-3 .btn-xs"
          >
            Add Image
          </Button>
          <Button
            onClick={createModule}
            className="me-2 mb-3 .btn-xs"
            variant="success"
          >
            Create Module
          </Button>
        </Col>
      </Row>

      <Modal show={showQuizModal} onHide={() => setShowQuizModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Quiz</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="quizQuestion">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter question"
                value={quizQuestion}
                onChange={(e) => setQuizQuestion(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="quizOptions" className="mt-3">
              <Form.Label>Options</Form.Label>
              {quizOptions.map((option, index) => (
                <Form.Control
                  key={index}
                  type="text"
                  placeholder={`Option ${index + 1}`}
                  value={option}
                  onChange={(e) =>
                    handleQuizOptionChange(index, e.target.value)
                  }
                  className="mb-2"
                />
              ))}
            </Form.Group>
            <Form.Group controlId="quizAnswer" className="mt-3">
              <Form.Label>Correct Answer</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter correct answer"
                value={quizAnswer}
                onChange={(e) => setQuizAnswer(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQuizModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleQuizChange}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showImageModal} onHide={() => setShowImageModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="imageFile">
              <Form.Label>Upload Image</Form.Label>
              <Form.Control
                type="file"
                onChange={(e) => setImageFile(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group controlId="imageLink" className="mt-3">
              <Form.Label>Image Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter link for the image"
                value={imageLink}
                onChange={(e) => setImageLink(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowImageModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleImageChange(imageFile);
              handleImageLinkChange();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default MobileSlider;
