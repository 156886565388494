import { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Cookies from 'js-cookie';

import avatar from "../../../images/avatar/nurse.png";
import avatar1 from "../../../images/avatar/2.jpg";
import avatar2 from "../../../images/avatar/3.jpg";
import avatar3 from "../../../images/avatar/4.jpg";
import avatar4 from "../../../images/avatar/5.jpg";

// const apiUrl = 'http://localhost:3000';
const apiUrl = 'https://quiz-ws.azurewebsites.net';

const Timeline = () => {
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchNurses = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          throw new Error("JWT token not found");
        }

        const response = await fetch(`${apiUrl}/api/admin/users`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${jwtToken}`
          }
        });

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);
        
        const users = await response.json();
        const filteredNurses = users.filter(user => user.role === 'nurse');

        // Sort nurses by reviews or any other criteria you prefer
        filteredNurses.sort((a, b) => b.reviews - a.reviews);

        // Get top 10 nurses
        const topNurses = filteredNurses.slice(0, 10);
        
        // Assuming the API returns the necessary data structure
        const nurseData = topNurses.map((nurse, index) => ({
          name: `${nurse.firstName} ${nurse.lastName}`,
          rank: index + 1,
          designation: "Nurse", // Assuming all users have the designation 'Nurse'
          review: nurse.reviews || 0, // Assuming there's a reviews field
          aStar: Array(5).fill(0).map((_, i) => i < nurse.rating ? 1 : 0), // Assuming there's a rating field out of 5
          img: [avatar, avatar1, avatar2, avatar3, avatar4][index % 5] // Assigning avatar images in a cyclic manner
        }));

        setData(nurseData);
      } catch (error) {
        console.error(error);
        toast.error("Error fetching nurses");
      }
    };

    fetchNurses();
  }, []);

  const onClick = () => {
    setRefresh(true);
    setTimeout(() => {
      setData([
        ...data,
        data[Math.floor(Math.random() * Math.floor(data.length - 1))],
      ]);
      setRefresh(false);
    }, 1000);
  };

  return (
    <div className="card">
      <ToastContainer />
      <div className="card-header border-0 pb-0">
        <h4 className="card-title">Top 10 Best Performers</h4>
        <Link className="btn-link ms-auto" to={"#"}>
          More {" >> "}
        </Link>
      </div>
      <div className="card-body">
        <div className="widget-media best-doctor">
          <div
            id="bestDoctorsContent"
            className=" dz-scroll height630"
          >
            <ul className="timeline">
              {data.map((d, i) => (
                <li key={i}>
                  <div className="timeline-panel">
                    <div className="media me-4">
                      {/* <img src={d.img} width={90} alt="" /> */}
                      <img src={avatar} width={90} alt="" />
                      <div className="number">#{d.rank}</div>
                    </div>
                    <div className="media-body">
                      <h4 className="mb-2">{d.name}</h4>
                      <p className="mb-2 text-primary">{d.designation}</p>
                      <div className="star-review">
                        {d.aStar.map((e, i) =>
                          e > 0 ? (
                            <Fragment key={i}>
                              <i className="fa fa-star text-orange" />{" "}
                            </Fragment>
                          ) : (
                            <Fragment key={i}>
                              <i className="fa fa-star text-gray" />{" "}
                            </Fragment>
                          )
                        )}

                        <span className="ms-3">{d.review} reviews</span>
                      </div>
                    </div>
                    <div className="social-media">
                      {/* <Link
                        to={"#"}
                        className="btn btn-outline-primary btn-rounded me-1 btn-sm"
                      >
                        <i className="fab fa-instagram " />
                      </Link>
                      <Link
                        to={"#"}
                        className="btn btn-outline-primary btn-rounded me-1  btn-sm"
                      >
                        <i className="fab fa-twitter" />
                      </Link>
                      <Link
                        to={"#"}
                        className="btn btn-outline-primary btn-rounded me-1 btn-sm"
                      >
                        <i className="fab fa-facebook-f " />
                      </Link> */}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-footer border-0 pt-0 text-center">
        <Link to={"#"} className="btn-link" onClick={() => onClick()}>
          See More {" >> "}
          {refresh && <i className="fa fa-refresh"></i>}
        </Link>
      </div>
    </div>
  );
};

export default Timeline;
