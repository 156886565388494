import React, { Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { Accordion, Row, Col, Spinner } from "react-bootstrap";
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';

import avatar from "../../../images/avatar/nurse.png";

const UiAccordion = () => {
  const [nurseData, setNurseData] = useState([]);
  const [loading, setLoading] = useState(true);
  // const apiUrl = 'http://localhost:3000';
  const apiUrl = 'https://quiz-ws.azurewebsites.net';

  useEffect(() => {
    const fetchNurses = async () => {
      try {
        const jwtToken = Cookies.get("jwtToken");
        if (!jwtToken) {
          throw new Error("JWT token not found");
        }

        const response = await fetch(`${apiUrl}/api/admin/users`, {
          method: 'GET',
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${jwtToken}`
          }
        });

        if (!response.ok) throw new Error(`Error: ${response.statusText}`);
        
        const users = await response.json();
        const nurses = users.filter(user => user.role === 'nurse');
        const groupedNurses = groupNursesByAlphabet(nurses);
        
        setNurseData(groupedNurses);
      } catch (error) {
        Swal.fire("Error", "Failed to fetch users", "error");
        console.error("Failed to fetch nurses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNurses();
  }, []);

  const groupNursesByAlphabet = (nurses) => {
    const grouped = {};
    nurses.forEach((nurse) => {
      const initial = (nurse.firstName || nurse.lastName || '').charAt(0).toUpperCase();
      if (!grouped[initial]) grouped[initial] = [];
      grouped[initial].push({
        name: `${nurse.firstName} ${nurse.lastName}`,
        subtitle: nurse.role,
        image: avatar, // use a default avatar image
        review: Math.floor(Math.random() * 500), // example review count
        star: 0 // placeholder for star rating if needed
      });
    });

    // Convert grouped object to an array, then sort alphabetically by the title (initial letter)
    return Object.entries(grouped)
      .map(([title, subcontent]) => ({ title, number: subcontent.length, subcontent }))
      .sort((a, b) => a.title.localeCompare(b.title));
  };

  return (
    <Fragment>
      <Row>
        <Col xl="12" lg="12">
          <Accordion className="accordion doctor-list accort-doctor" defaultActiveKey="0">
            {loading ? (
              <div className="text-center my-4">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              nurseData.map((data, index) => (
                <Accordion.Item className="accordion-item" eventKey={`${index}`} key={index}>
                  <Accordion.Header className="accordion_header rounded-lg">
                    <span className="accordion__header-alphabet">{data.title}</span>
                    <span className="accordion__header-line flex-grow-1"></span>
                    <span className="accordion__header--text">{data.number} Employees</span>
                  </Accordion.Header>
                  <Accordion.Collapse eventKey={`${index}`}>
                    <div className="widget-media best-doctor pt-4">
                      <div className="timeline row">
                        {data.subcontent.map((item, subIndex) => (
                          <div className="col-lg-6" key={subIndex}>
                            <div className="timeline-panel bg-white p-4 mb-4">
                              <div className="media me-4">
                                {/* <img src={item.image} width={90} alt={item.name} /> */}
                                <img src={avatar} width={90} alt={item.name} />
                              </div>
                              <div className="media-body">
                                <h4 className="mb-2">{item.name}</h4>
                                <p className="mb-2 text-primary">{item.subtitle}</p>
                                <div className="star-review">
                                  <i className="fa fa-star text-orange me-1" />
                                  <i className="fa fa-star text-orange me-1" />
                                  <i className="fa fa-star text-orange me-1" />
                                  <i className="fa fa-star text-gray me-1" />
                                  <i className="fa fa-star text-gray me-1" />
                                  {/* <span className="ms-3">{item.review} reviews</span> */}
                                  <span className="ms-3">Top Performer</span>
                                </div>
                              </div>
                              <div className="social-media">
                                <Link to={"#"} className="btn btn-outline-primary btn-rounded btn-sm me-1">
                                  <i className="fab fa-instagram" />
                                </Link>
                                <Link to={"#"} className="btn btn-outline-primary btn-rounded btn-sm me-1">
                                  <i className="fab fa-twitter" />
                                </Link>
                                <Link to={"#"} className="btn btn-outline-primary btn-rounded btn-sm me-1">
                                  <i className="fab fa-facebook-f" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Accordion.Collapse>
                </Accordion.Item>
              ))
            )}
          </Accordion>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UiAccordion;
