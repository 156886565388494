import React, { useState, useEffect } from "react";
import { Dropdown, Modal, Card, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import NursesAccordion from "./NursesAccordion";
import { ToastContainer, toast } from 'react-toastify';
import { Button, Form, Row, Col } from 'react-bootstrap';
import Cookies from 'js-cookie';
import { Spinner } from "react-bootstrap";

const Nurses = () => { 
  // const apiUrl = 'http://localhost:3000';
  const apiUrl = 'https://quiz-ws.azurewebsites.net';
 
  const [addNurse, setAddNurse] = useState(false);
  const [editNurse, setEditNurse] = useState(false);
  const [isAccordionVisible, setIsAccordionVisible] = useState(false);
  const [nurses, setNurses] = useState([]);
  const [selectedNurse, setSelectedNurse] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading

  const [formData, setFormData] = useState({
    emplpoyeeId: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    password: '',
    role: 'nurse'
  });

  const fetchNurses = async () => {
    try {
      setLoading(true); // Start loading
      const jwtToken = Cookies.get("jwtToken");
      if (!jwtToken) {
        throw new Error("JWT token not found");
      }

      const response = await fetch(`${apiUrl}/api/admin/users`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${jwtToken}`
        }
      });

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      
      const users = await response.json();
      const filteredNurses = users.filter(user => user.role === 'nurse');
      setNurses(filteredNurses);
    } catch (error) {
      console.error(error);
      toast.error("Error fetching nurses");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchNurses();
  }, []);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await fetch(`${apiUrl}/api/admin/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        notifySuccess("Nurse added successfully!");
        setAddNurse(false);
        resetForm();
        fetchNurses();
      } else {
        throw new Error('Failed to add nurse');
      }
    } catch (error) {
      console.error(error);
      toast.error("Error adding nurse");
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await fetch(`${apiUrl}/api/admin/users/${selectedNurse.phoneNumber}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwtToken}`
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        notifySuccess("Nurse updated successfully!");
        setEditNurse(false);
        setSelectedNurse(null);
        resetForm();
        fetchNurses();
      } else {
        throw new Error('Failed to update nurse');
      }
    } catch (error) {
      console.error(error);
      toast.error("Error updating nurse");
    }
  };

  const handleDelete = async (nurseId) => {
    try {
      const jwtToken = Cookies.get('jwtToken');
      const response = await fetch(`${apiUrl}/api/admin/users/${nurseId}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${jwtToken}`
        }
      });

      if (response.ok) {
        notifySuccess("Nurse deleted successfully!");
        fetchNurses();
      } else {
        throw new Error('Failed to delete nurse');
      }
    } catch (error) {
      console.error(error);
      toast.error("Error deleting nurse");
    }
  };

  const handleEditClick = (nurse) => {
    setSelectedNurse(nurse);
    setFormData({
      emplpoyeeId: nurse.emplpoyeeId,
      firstName: nurse.firstName,
      lastName: nurse.lastName,
      phoneNumber: nurse.phoneNumber,
      email: nurse.email,
      password: '',
      role: nurse.role
    });
    setEditNurse(true);
  };

  const resetForm = () => {
    setFormData({
      emplpoyeeId: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      password: '',
      role: 'nurse'
    });
  };

  const handleCloseAddModal = () => {
    setAddNurse(false);
    resetForm();
  };

  const handleCloseEditModal = () => {
    setEditNurse(false);
    setSelectedNurse(null);
    resetForm();
  };

  const chackboxFun = (type) => {
    setTimeout(() => {
      const chackbox = document.querySelectorAll(".bs_exam_topper input");
      const motherChackBox = document.querySelector(".bs_exam_topper_all input");
      for (let i = 0; i < chackbox.length; i++) {
        const element = chackbox[i];
        if (type === "all") {
          if (motherChackBox.checked) {
            element.checked = true;
          } else {
            element.checked = false;
          }
        } else {
          if (!element.checked) {
            motherChackBox.checked = false;
            break;
          } else {
            motherChackBox.checked = true;
          }
        }
      }
    }, 100);
  };
  
  return (
    <React.Fragment>
      <ToastContainer />
      <div className="form-head d-flex mb-3 mb-lg-5 align-items-start flex-wrap">
        <Link onClick={() => setAddNurse(true)} className="btn btn-danger">
          + New Employee
        </Link>
        <div className="input-group search-area ms-auto d-inline-flex">
          <input type="text" className="form-control" placeholder="Search here" />
          <span className="input-group-text c-pointer">
            <i className="flaticon-381-search-2"></i>
          </span>
        </div>
        <Dropdown className="ms-3">
          <Dropdown.Toggle variant="outline-primary " id="dropdown-basic">
            <i className="flaticon-381-equal-1 mr-3"></i>Select Unit
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-menu-right">
            <Dropdown.Item href="#">Clinics</Dropdown.Item>
            <Dropdown.Item href="#">Diagnostics</Dropdown.Item>
            <Dropdown.Item href="#">Hospitals</Dropdown.Item>
            <Dropdown.Item href="#">Dental</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Link 
          onClick={() => setIsAccordionVisible(false)} 
          className="btn btn-outline-primary ms-3"
        >
          <i className="flaticon-381-menu-1 me-0"></i>
        </Link>
        <Link 
          onClick={() => setIsAccordionVisible(true)} 
          className="btn btn-light ms-3"
        >
          <i className="flaticon-381-pad me-0"></i>
        </Link>
      </div>
      
      {loading ? (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
          {/* <ClipLoader size={50} color="#123abc" /> */}
          <Spinner size={50} color="#123abc" />
        </div>
      ) : isAccordionVisible ? (
        <NursesAccordion />
      ) : (
        <Card>
          <Card.Header>
            <Card.Title>Employee List</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th className="width50 ">
                    <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper_all">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkAll"
                        required=""
                        onClick={() => chackboxFun("all")}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkAll"
                      ></label>
                    </div>
                  </th>
                  <th>
                    <strong>EMPLOYEE ID</strong>
                  </th>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Email</strong>
                  </th>
                  <th>
                    <strong>Phone</strong>
                  </th>
                  <th>
                    <strong>Score</strong>
                  </th>
                  <th>
                    <strong>Actions</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {nurses.map((nurse, index) => (
                  <tr key={nurse._id}>
                    <td>
                      <div className="form-check custom-checkbox checkbox-success check-lg me-3 bs_exam_topper">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id={`customCheckBox${index}`}
                          required=""
                          onClick={() => chackboxFun()}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`customCheckBox${index}`}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <strong>{nurse.emplpoyeeId}</strong>
                    </td>
                    <td>
                      <div className="d-flex align-items-center">
                        <span className="w-space-no">{nurse.firstName} {nurse.lastName}</span>
                      </div>
                    </td>
                    <td>{nurse.email}</td>
                    <td>{nurse.phoneNumber}</td>
                    <td>
                      <div className="d-flex align-items-center">
                        <i className="fa fa-circle text-success me-1"></i> 
                        {nurse.completedQuizzes.reduce((total, quiz) => total + quiz.score, 0)}
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link
                          onClick={() => handleEditClick(nurse)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fas fa-pen"></i>
                        </Link>
                        <Link
                          onClick={() => handleDelete(nurse.phoneNumber)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}

      <Modal className="modal fade" centered show={addNurse} onHide={handleCloseAddModal}>
        <div className="modal-header">
          <h1 className="modal-title fs-5">Add Nurse</h1>
          <button type="button" className="btn-close" onClick={handleCloseAddModal}></button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="emplpoyeeId"
                    placeholder="E12345"
                    value={formData.emplpoyeeId}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="Steve"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="T"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    placeholder="+9123654789"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                  >
                    <option value="nurse">Nurse</option>
                    <option value="instructor">Instructor</option>
                    <option value="admin">Admin</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button variant="danger" onClick={handleCloseAddModal}>Close</Button>
          <Button variant="primary" onClick={handleSubmit}>Save changes</Button>
        </div>
      </Modal>

      <Modal className="modal fade" centered show={editNurse} onHide={handleCloseEditModal}>
        <div className="modal-header">
          <h1 className="modal-title fs-5">Edit Nurse</h1>
          <button type="button" className="btn-close" onClick={handleCloseEditModal}></button>
        </div>
        <div className="modal-body">
          <Form onSubmit={handleEdit}>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Employee ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="emplpoyeeId"
                    placeholder="E12345"
                    value={formData.emplpoyeeId}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="Raju"
                    value={formData.firstName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="T"
                    value={formData.lastName}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phoneNumber"
                    placeholder="+9123654789"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
              <Col xl="6">
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6">
              <Form.Group className="mb-3">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    name="role"
                    value={formData.role}
                    onChange={handleInputChange}
                  >
                    <option value="nurse">Nurse</option>
                    <option value="instructor">Instructor</option>
                    <option value="admin">Admin</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </div>
        <div className="modal-footer">
          <Button variant="danger" onClick={handleCloseEditModal}>Close</Button>
          <Button variant="primary" onClick={handleEdit}>Save changes</Button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Nurses;
